import React from "react";
import { Link } from "react-router-dom";

const BookDemoButton = ({ mode = "light" }) => {
  return (
    <Link to="https://cal.com/traddocs/30min" target="_blank" rel="noopener noreferrer">
      <button
        className={`${
          mode === "dark" ? "text-gray-700 bg-white" : "bg-gray-700 text-white"
        } text-base font-normal h-9 px-3 md:px-5 rounded-full transition flex-shrink-0 hover:brightness-125`}
      >
        Book a demo
      </button>
    </Link>
  );
};

export default BookDemoButton;
